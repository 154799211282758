<template>
  <div class="event-info-or">
    <div>
      <div class="time">{{ detectTime }}</div>
    </div>
    <div class="group">
      <div class="group-tag">{{ $t('device')/*設備*/ }}</div>
      <div class="items">
        <div class="item">
          <img src="@/assets/icons/Group.svg" alt="">
          <span class="group-name">{{ getGroupName(event.groupId) }}</span>
        </div>
        <div class="item">
          <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow placement="left">
            <img src="@/assets/icons/Camera.svg" alt="">
          </TooltipElement>
          <span>{{ getDeviceTitleId(event.user.id) }}</span>
        </div>
        <div v-if="!isPrintScreen" class="item show-more" :class="{ 'is-show': showMore }" @click="showMore = !showMore">
          <img src="@/assets/icons/arrow-down.svg" alt="">
          <span v-if="showMore">{{ $t('less') }}</span>
          <span v-else>{{ $t('more') }}</span>
        </div>
        <template v-if="showMore || isPrintScreen">
          <div class="item">
            <TooltipElement :content="$t('account_label') /*帳號名稱*/" alwaysShow placement="left">
              <img src="@/assets/icons/account-ab.svg" alt="">
            </TooltipElement>
            <span>{{ event.user.info.name }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('account_device_model') /*設備類型*/" alwaysShow placement="left">
              <img :src="deviceModelIcon()" alt="">
            </TooltipElement>
            <span>{{ getDeviceModelName() }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('am_group_dev_info_device_info_type')/*裝置類型*/" alwaysShow placement="left">
              <img src="@/assets/icons/Type.svg" alt="">
            </TooltipElement>
            <span>{{ event.device.info.type }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('am_group_dev_info_device_id')/*設備序號*/" alwaysShow placement="left">
              <img src="@/assets/icons/udid.svg" alt="">
            </TooltipElement>
            <span>{{ event.device.id }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="group">
      <div class="group-tag">{{ $t('history_recognition')/*辨識*/ }}</div>
      <div class="items">
        <div class="item">
          <TooltipElement :content="$t('event_notes')/*備註*/" alwaysShow placement="left">
            <img src="@/assets/icons/note.svg" alt="">
          </TooltipElement>
          <span>{{ event.notice }}</span>
        </div>
        <!-- <div class="item">
          <TooltipElement :content="$t('history_tag')/*車輛標記*/" alwaysShow placement="left">
            <img src="@/assets/icons/source.svg" alt="">
          </TooltipElement>
          <span>{{ $t('or_cloud')/*雲端*/ }}</span>
        </div>
        <div class="item">
          <TooltipElement :content="$t('history_tag')/*車輛標記*/" alwaysShow placement="left">
            <img src="@/assets/icons/udid.svg" alt="">
          </TooltipElement>
          <span>{{ udid }}</span>
        </div> -->
      </div>
    </div>
    <div v-if="Object.keys(objsClassCount).length > 0" class="group" :class="{ 'no-click': isBackPlay }">
      <div class="group-tag">{{ $t('history_event_or')/*物件*/ }}</div>
      <div class="items" :class="{ 'no-click': isBackPlay }"> 
        <div class="show-all" @click="toggleShowTarget">
          <img v-if="hideAllObjs" src="@/assets/icons/checkbox-white-square.svg" alt="">
          <img v-else-if="showAllObjs" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
          <img v-else src="@/assets/icons/checkbox-yellow-minus.svg" alt="">
          <span>{{ $t('show_all')/*顯示全部*/ }}</span>
        </div>
        <div class="detect-objs">
          <div
            v-for="(item, key) in objsClassCount"
            :key="key"
            class="obj"
            :class="[ item.isShow ? classMap[key] : '']"
            @click="item.isShow = !item.isShow"
          >
            <TooltipElement :content="$t(`or_class_${key}`)" placement="top" alwaysShow>
              <img :src="classIcon[key]" alt="">
              <div class="count">
                <div>{{ item.count }}</div>
              </div>
            </TooltipElement>
          </div>
        </div>
      </div>
    </div>
    <div class="group">
      <div class="group-tag">{{ $t('ai_warning_area')/*警戒區*/ }}</div>
      <div class="items" :class="{ 'no-click': isBackPlay }">
        <template v-if="Object.keys(detectAreas).length > 0">
          <div class="show-all" @click="toggleShowArea">
            <div class="check-box">
              <img v-if="hideAllAreas" src="@/assets/icons/checkbox-white-square.svg" alt="">
              <img v-else-if="showAllAreas" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
              <img v-else src="@/assets/icons/checkbox-yellow-minus.svg" alt="">
            </div>
            <span>{{ $t('show_all')/*顯示全部*/ }}</span>
          </div>
          <div class="detect-area">
            <div
              v-for="(item, key) in detectAreas"
              :key="key"
              class="area"
              :class="[ item.isShow ? key : '' ]"
              @click="item.isShow = !item.isShow"
            >
              <span>{{ areaName[key] }}</span>
            </div>
          </div>
        </template>
        <div class="full" v-else>{{ $t('or_full_view')/*全畫面*/ }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { apiEditBoviaLprEvent } from '@/api/index.js'
import { formatTime } from '@/utils/lib.js'
import { getDeviceModelIcon } from '@/config/account.js'

export default {
  name: 'EventInfoOr',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object
    },
    isPrintScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowTooltip: false,
      udid: '',
      classMap: {
        1: 'person',
        2: 'car',
        3: 'bicycle',
        4: 'truck',
        5: 'bus',
      },
      classIcon: {
        1: require('@/assets/icons/person.svg'),
        2: require('@/assets/icons/car.svg'),
        3: require('@/assets/icons/bicycle.svg'),
        4: require('@/assets/icons/truck.svg'),
        5: require('@/assets/icons/bus.svg'),
      },
      areaName: {
        area1: this.$t('ai_area1'),
        area2: this.$t('ai_area2'),
        area3: this.$t('ai_area3'),
      },
      showMore: false,
    }
  },
  computed: {
    ...mapState('global', ['deviceModels']),
    ...mapState('account', ['groupList']),
    ...mapState('historyOr', ['objsClassCount', 'detectAreas']),
    ...mapState('video', ['isBackPlay']),
    ...mapGetters('account', ['getDeviceTitleId', 'getDeviceModelId']),
    detectTime() {
      return formatTime(this.event.detectTime)
    },
    showAllObjs: {
      get() {
        return Object.keys(this.objsClassCount).every(key => this.objsClassCount[key].isShow)
      },
      set(val) {
        Object.keys(this.objsClassCount).forEach(key => {
          this.objsClassCount[key].isShow = val
        })
      }
    },
    showAllAreas: {
      get() {
        let hideCount = 0
        Object.keys(this.detectAreas).forEach(key => {
          if (this.detectAreas[key].isShow === false) hideCount++
        })
        return hideCount === 0
      },
      set(val) {
        Object.keys(this.detectAreas).forEach(key => {
          this.detectAreas[key].isShow = val
        })
      }
    },
    hideAllObjs() {
      return Object.keys(this.objsClassCount).every(key => !this.objsClassCount[key].isShow)
    },
    hideAllAreas() {
      return Object.keys(this.detectAreas).every(key => !this.detectAreas[key].isShow)
    },
    deviceModelId() {
      return this.getDeviceModelId(this.event.user.id)
    }
  },
  methods: {
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.event.latitude},${this.event.longitude}`
      window.open(url, '_blank')
    },
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
    onMouseOver(str) {
      const tag = this.$refs[str]
      const contentWidth = tag.offsetWidth
      const contentHeight = tag.offsetHeight
      const scrollWidth = tag.scrollWidth
      const scrollHeight = tag.scrollHeight
      this.isShowTooltip = contentWidth < scrollWidth || contentHeight < scrollHeight
    },
    toggleShowTarget() {
      this.showAllObjs = !this.showAllObjs
    },
    toggleShowArea() {
      this.showAllAreas = !this.showAllAreas
    },
    deviceModelIcon() {
      const icon = getDeviceModelIcon(this.deviceModelId)
      return require(`@/assets/icons/device_model_${icon}.svg`)
    },
    getDeviceModelName() {
      const findObj = this.deviceModels.find(item => item.id === this.deviceModelId)
      return findObj ? findObj.name : ''
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.event-info-or {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .time {
    display: inline-block;
    align-items: center;
    height: 34px;
    padding: 0 20px;
    border-radius: 17px;
    background: #4A5C78;
    font-size: 18px;
    line-height: 34px;
    font-weight: 700;
    color: #ffffff;
  }

  .group {
    display: flex;
    column-gap: 12px;
    padding-bottom: 8px;
    &:not(:last-child) {
      border-bottom: 1px solid #ffffff33;
    }

    .group-tag {
      min-width: 80px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #FFE99F;  
    }

    .items {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      &.no-click {
        pointer-events: none;
      }
      
      .item {
        display: flex;
        align-items: flex-start;
        column-gap: 8px;
        &.show-more {
          cursor: pointer;

          span {
            text-decoration: underline;
          }

          &.is-show {
            img {
              transform: rotate(180deg);
            }
          }
        }
        
        img {
          width: 20px;
          height: 20px;
          margin-top: 4px;
          transition: all 200ms ease-in-out;
        }
        span {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #ffffff;
          word-break: break-all;

          &.group-name {
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #FFD133;
          }
        }
      }
  
      .show-all {
        display: flex;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #ffffff;
          user-select: none;
        }
      }

      .full {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }

  .detect-objs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-bottom: 8px;
        
    .obj {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #ffffff33;
      cursor: pointer;
  
      img {
        width: 32px;
        height: 32px;
        @include filter_FFF_30;
      }
  
      &.person {
        background: $color_person_50;
        img {
          @include filter_FFF;
        }
      }
      &.car {
        background: $color_car_50;
        img {
          @include filter_FFF;
        }
      }
      &.bicycle {
        background: $color_bicycle_50;
        img {
          @include filter_FFF;
        }
      }
      &.truck {
        background: $color_truck_50;
        img {
          @include filter_FFF;
        }
      }
      &.bus {
        background: $color_bus_50;
        img {
          @include filter_FFF;
        }
      }
      
      .count {
        position: absolute;
        right: -8px;
        bottom: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background: #191919;
        font-size: 14px;
        & > div {
          position: relative;
          top: -1px;
          color: #ffffff;
        }
      }
    }
  }

  .detect-area {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 8px;
        
    .area {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #ffffff33;
      cursor: pointer;
      
      span {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.5px;
        font-weight: 400;
        color: #ffffff4d;
        user-select: none;
      }
  
      &.area1 {
        background: $color_area1_50;
        span {
          color: #ffffff;
        }
      }
      &.area2 {
        background: $color_area2_50;
        span {
          color: #ffffff;
        }
      }
      &.area3 {
        background: $color_area3_50;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
</style>